<template>
    <div class="accordion active medium-title button-1">
      <div class="accordion-header">
        <div class="title">MTS course characteristics</div>
        <div class="header-container">
          <div class="toggle"></div>
        </div>
      </div>
      <div class="accordion-content">
        <template v-if="showTable">
          <characteristic-row v-for="char in characteristicData[0].characteristics" 
            :key="char" 
            :courseDetail="true"
            :characteristic="char"
          >
          </characteristic-row>
        </template>
        <div v-else class="table-row empty">
          No characteristics have been wired
        </div>
      </div>
    </div>
  </template>

  <script>
  import { mapState } from "pinia";
  import { useCharacteristicsStore } from "../../stores/characteristics.js";

  import CharacteristicRow from "./CharacteristicRow.vue";

  export default {
    name: "CourseTailoringTable",
    components: {
      CharacteristicRow,
    },
    data() {
      return {};
    },
    props: {
      characteristicData: {
        type: Array,
        required: true,
      },
    },
    computed: {
      ...mapState(useCharacteristicsStore, ["coachId"]),
      showTable() {
        return (
          (this.characteristicData[0] && this.characteristicData[0].characteristics.length != 0)
        );
      },
    },
    methods: {
      editURL(id) {
          return `/Dashboard/Coach/${this.coachId}/CoachDetails/EditCourseCharacteristic/${id}/`;
      },
    },
  };
  </script>
