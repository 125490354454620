<template>
  <div class="accordion active medium-title button-1">
    <div class="accordion-header">
      <div class="title">{{ tableHeader }}</div>
      <div class="header-container">
        <div class="table-header">MTS Characteristic</div>
        <div v-if="!isToDoTable" class="table-header">Document</div>
        <div class="table-header status-column">Status</div>
        <div class="toggle"></div>
      </div>
    </div>
    <div class="accordion-content">
      <template v-if="showTable">
        <div v-for="d in sortedCharacteristicData" :key="d.id" class="table-row">
          <div class="row-title overflow-path">
            <template v-if="d.title">
              <div class="truncate">{{ contentTitle(d) }}</div>
              <span class="tooltip-text">{{ contentTitle(d) }}</span>
            </template>
            <span v-else>No title</span>
          </div>
          <div class="info-block">
            <div class="row-item char-wiring overflow-parent">
              <characteristic-row v-for="char in d.characteristics"
                :key="char"
                :characteristic="char">
              </characteristic-row>
            </div>
            <div v-if="!isToDoTable" class="row-item overflow-path">
                <template v-if="d.document_name">
                    <div class="truncate">{{ d.document_name }}</div>
                    <span class="tooltip-text">{{ d.document_name }}</span>
                </template>
                <span v-else>No document</span>
            </div>
            <div class="row-item status-column">{{ d.status }}</div>
            <div class="buttons">
              <a
                v-if="title != 'Email'"
                class="edit"
                :href="editURL(d.id)"
                :aria-label="`Edit ${title}: ${d.title}`"
              >
                <span class="tooltip-text">Edit {{ title }}</span>
              </a>
              <a
                v-else-if="d.status != 'Delivered'"
                class="edit"
                :href="editURL(d.id)"
                :aria-label="`Edit ${title}: ${d.title}`"
              >
                <span class="tooltip-text">Edit {{ title }}</span>
              </a>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="table-row empty">
        No characteristics have been wired for {{ tableHeader }}
      </div>
    </div>
  </div>
</template>

  <script>
import { mapState } from "pinia";
import { useCharacteristicsStore } from "../../stores/characteristics.js";

import CharacteristicRow from "./CharacteristicRow.vue";

export default {
  name: "CharacteristicTable",
  data() {
    return {};
  },
  components: {
    CharacteristicRow,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    characteristicData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(useCharacteristicsStore, ["coachId"]),
    showTable() {
      return (
        this.characteristicData.length != 0 ||
        (this.characteristicData[0] && this.characteristicData[0].characteristics.length != 0)
      );
    },
    sortedCharacteristicData() {
      let order =
        this.title == "Email"
          ? ["Draft", "Scheduled", "Delivered"]
          : ["Draft", "Scheduled", "Published"];
      return this.characteristicData.sort(
        (a, b) => order.indexOf(a.status) - order.indexOf(b.status)
      );
    },
    tableHeader() {
      return this.title + "s";
    },
    isToDoTable() {
      return this.title == "To-Do List";
    },
  },
  methods: {
    contentTitle(item) {
      if (this.title == "Bulletin") {
        return item.title + " | " + item.subtitle;
      }
      return item.title;
    },
    editURL(id) {
      return this.isToDoTable
        ? `/Dashboard/Coach/${this.coachId}/ToDoManager/EditToDoList/${id}/`
        : `/Dashboard/Coach/${this.coachId}/ContentManager/Edit${this.title}/${id}/`;
    },
  },
};
</script>
