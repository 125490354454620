<template>
  <div v-if="courseDetail" class="table-row">
    <div class="row-title char-item">
      {{ charLabel }}: <b><span>{{ charValue }}</span></b>
    </div>
    <div class="buttons">
      <a
        class="edit"
        :href="`/Dashboard/Coach/${coachId}/CoachDetails/EditCourseCharacteristic/${characteristic.id}/`"
        :aria-label="`Edit characteristic: ${charLabel}`"
      >
        <span class="tooltip-text">Edit characteristic</span>
      </a>
    </div>
  </div>
  <div v-else class="char-item overflow-path" :class="wiredClass">
    <div class="truncate">{{ charLabel }}: <b><span>{{ charValue }}</span></b></div>
    <span class="tooltip-text">{{ charLabel }}: <b><span>{{ charValue }}</span></b></span>
    <div
        v-if="charValue !== 'not wired'"
        class="copy-dropdown dropdown copy-mts-char">
        <button
            class="dropdown dropdown-toggle"
            type="button" id=""
            data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"
            :aria-label="`Copy MTS Characteristic: ${ charValue }`">
            <div class="icon-container">
                <svg class="icon link-icon icon-navy">
                    <use :xlink:href="copyIcon"></use>
                </svg>
            </div>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton_id_{{message.id}}">
            <p class="copyText copy-item">
                {{ charValue }}
            </p>
            <button class="btn btnCopyText" type="button">Copy</button>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCharacteristicsStore } from "../../stores/characteristics.js";
import { copyIcon } from "../../images.js";

export default {
  name: "CharacteristicRow",
  data() {
    return {
        copyIcon: copyIcon,
    };
  },
  props: {
    characteristic: {
      type: Array,
      required: true,
    },
    courseDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useCharacteristicsStore, ["coachId"]),
    charLabel() {
      return this.characteristic.label;
    },
    charValue() {
      return this.characteristic.value ? this.characteristic.value : "not wired";
    },
    wiredClass() {
        return this.charValue !== 'not wired' ? "is-wired-char" : "" ;
    },
  },
};
</script>
