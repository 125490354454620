<template>
  <loading-indicator v-if="loading" />
  <div v-else class="todo-list">
    <div class="card-header">
      <h1>To-Dos</h1>
      <div class="todo-list-nav card-nav" id="listNavigation">
        <template v-if="showPrevButton">
          <button
            class="link nav-item nav-prev"
            type="button"
            aria-label="Previous List"
            @click="loadPrev()"
          >
            <div class="icon-container">
              <svg class="icon-arrow icon-navy" role="presentation">
                <use :xlink:href="leftArrow"></use>
              </svg>
            </div>
            Previous List
          </button>
        </template>
        <div class="divider" v-if="showPrevButton && showNextButton"></div>
        <template v-if="showNextButton">
          <button
            class="link nav-item nav-next"
            type="button"
            data-slide="next"
            aria-label="Next List"
            @click="loadNext()"
          >
            Next List
            <div class="icon-container">
              <svg class="icon-arrow icon-navy" role="presentation">
                <use :xlink:href="rightArrow"></use>
              </svg>
            </div>
          </button>
        </template>
      </div>
    </div>
    <div v-if="!(todoListData)" class="card card-empty">
        <div class="icon-container">
            <svg class="empty-icon" role="presentation">
                <use xlink:href="/static/img/IconSprite.svg#toDo"></use>
            </svg>
        </div>
        <p><i>There are currently no To-Do items.</i></p>
    </div>
    <div v-else class="card card-to-do card-has-overflow">
      <div class="nested-card-header header-plus-btn">
        <div v-if="todoListData.is_queued" class="status-label scheduled">
          Scheduled
        </div>
        <div v-if="todoListData.is_under_review" class="status-label draft">
          Drafted
        </div>
        <h3 v-if="todoListData.title" id="listTitle">
          {{ todoListData.title }}
        </h3>
        <h3 v-else class="empty-title">No list title</h3>
        <button v-if="sidebar === 'true'"
          class="btn btn-seafoam"
          @click="updateShowCreateModal()"
          type="button"
          aria-label="Add Your Own To-Do Item"
        >
          Add Your Own
        </button>
        <a v-else
          type="button"
          class="btn btn-seafoam"
          :href="createFormUrl"
          aria-label="Add Your Own To-Do Item"
        >
          Add Your Own
      </a>
      </div>
      <div class="todo-items">
        <to-do-item
          v-for="todoitem in todoListData.items"
          :key="todoitem.id"
          :todoitem="todoitem"
          :coachId="coachId"
          :sidebar="sidebar"
          @detail-editor="$emit('detail-editor')"
        />
      </div>
      <Teleport to="#slotWrapper">
        <to-do-list-modal v-if="showCreateModal || showUpdateModal"/>
      </Teleport>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTodoStore } from '../../stores/todo.js';
import { leftArrow, rightArrow } from "../../images.js";
import { todoItemCreateFormUrl } from "../../urls.js";
import { loadingMixin } from '../../mixins/loading.js';

import LoadingIndicator from "../generic/LoadingIndicator.vue";
import ToDoModal from "./ToDoModal.vue";
import ToDoItem from "./ToDoItem.vue";

export default {
  name: "ToDoList",
  props: {
    "sidebar": {
      default: false,
    }
  },
  mixins: [loadingMixin],
  data() {
    return {
      loading: true,
      leftArrow: leftArrow,
      rightArrow: rightArrow,
    };
  },
  components: {
    "to-do-list-modal": ToDoModal,
    "to-do-item": ToDoItem,
    LoadingIndicator,
  },
  methods: {
    ...mapActions(useTodoStore, [
      'loadTodoListData',
      'loadTodoItemTagsData',
      'displaySelectedTodoList',
      'updateCurrentPage',
      'updateTodoListUrl',
      'updateShowCreateModal',
    ]),
    loadNext() {
      this.updateCurrentPage(this.currentPage + 1);
      this.updateTodoListUrl(this.currentPage);
      this.loadTodoListData();
    },
    loadPrev() {
      this.updateCurrentPage(this.currentPage - 1);
      this.updateTodoListUrl(this.currentPage);
      this.loadTodoListData();
    },
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.loadTodoListData(),
        this.loadTodoItemTagsData(),
      ]).then(() => {this.stopLoading()})
    }
  },
  computed: {
    ...mapState(useTodoStore, [
      'todoListData',
      'currentPage',
      'showNextButton',
      'showPrevButton',
      'showCreateModal',
      'showUpdateModal',
    ]),
    coachId() {
      return this.todoListData.coach;
    },
    createFormUrl() {
      return todoItemCreateFormUrl.stringify({coachId: this.coachId, listId: this.todoListData.id});
    },
  },
  async created() {
    this.loadInterfaceData();
  },
};
</script>
