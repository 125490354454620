<template>
  <FormKit
    v-model="activeItemData"
    type="form"
    :form-class="formClasses"
    :actions="false"
    @submit="submitTodoForm"
  >
    <FormKit
      outer-class="survey_question"
      label-class="survey_prompt"
      name="title"
      :errors="this.formErrors['title']"
      validation="required"
      label="Title"
    >
      <template #label="context">
        <div :class="context.classes.label" :for="context.id">
          Title <span class="required-item">*</span>
        </div>
      </template>
    </FormKit>
    <FormKit
      type="hidden"
      name="todo_list"
      :value="todoListData.id || list"
    />
    <FormKit type="hidden" name="staff_created" :value="activeItemData.staff_created || false" />
    <FormKit type="hidden" name="account_item" :value="activeItemData.account_item" />
    <div class="formkit-outer form-field survey_question">
      <div class="formkit-label form-label survey_prompt" for="editor">
        Description <span class="required-item">*</span>
      </div>
      <QuillEditor
        theme="snow"
        ref="quillEditor"
        :toolbar="[['bold', 'italic', 'underline', 'strike'], [{'list': 'bullet'}, {'list': 'ordered'}], ['link']]"
        @ready="initializeEditor"
      />
    </div>
    <FormKit
      outer-class="survey_question"
      label-class="survey_prompt"
      input-class="datepicker"
      type="text"
      name="due_date"
      :errors="this.formErrors['due_date']"
      label="Due Date"
    />
    <FormKit v-if="coachToDoItemTags"
      outer-class="survey_question"
      legend-class="survey_prompt"
      fieldset-class="survey_responses"
      options-class="survey_responses"
      type="checkbox"
      label="Tags"
      name="item_tag_ids"
      :options="coachToDoItemTags"
    />
    <div class="button-block">
      <slot name="cancel-button"></slot>
      <FormKit outer-class="formulate-btn" type="submit" label="Save" />
    </div>
  </FormKit>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTodoStore } from "../../stores/todo.js";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "ToDoItemForm",
  data() {
    return {
      formData: {},
      formErrors: {},
    };
  },
  props: {
    modal: {
      default: false,
    },
    list: {
      default: undefined,
    },
  },
  components: {
    QuillEditor,
  },
  methods: {
    ...mapActions(useTodoStore, [
      "loadTodoListData",
      "updateToDoItem",
      "createToDoItem",
      "updateSelectedItemData",
      "loadTodoItemTagsData",
      "loadTodoItemData",
    ]),
    cleanData(data) {
      data.due_date = this.cleanDate(data.due_date);
      data.description = this.getDescription();
      return data;
    },
    cleanDate(dateString) {
      if (dateString != $(".datepicker").val()) {
        dateString = $(".datepicker").val();
      }
      let formattedDate = this.$luxonDateTime.fromFormat(
        dateString,
        "MM/dd/yyyy"
      );
      formattedDate = formattedDate.isValid
        ? formattedDate.toISODate()
        : dateString;
      return formattedDate ? formattedDate : null;
    },
    getDescription() {
      return this.editor.getHTML();
    },
    handleUpdate(response) {
      if (response.errors) {
        this.formErrors = response.errors;
      } else {
        this.formErrors = {};
        if(!this.modal) {
          window.location.href = this.redirectUrl;
        }
        this.loadTodoListData();
      }
    },
    editItem(data) {
      this.updateToDoItem(data).then((response) => {
        this.handleUpdate(response);
      });
    },
    initializeEditor() {
      if(this.selectedItemData.description) {
        this.editor.setHTML(this.selectedItemData.description);
      }
    },
    saveItem(data) {
      this.createToDoItem(data).then((response) => {
        this.handleUpdate(response);
      });
    },
    submitTodoForm(){
      let data = this.cleanData(this.activeItemData);
      this.instance ? this.editItem(data) : this.saveItem(data);
    },
    formatDate(date) {
      const formattedDate = this.$luxonDateTime.fromFormat(date, "yyyy-MM-dd");
      return formattedDate.isValid
        ? formattedDate.toFormat("MM/dd/yyyy")
        : date;
    },
  },
  computed: {
    ...mapState(useTodoStore, [
      "todoListData",
      "coachToDoItemTags",
      "selectedItemData",
      "redirectUrl"
    ]),
    activeItemData: {
      get() {
        if (this.selectedItemData.due_date) {
          this.selectedItemData.due_date = this.formatDate(
            this.selectedItemData.due_date
          );
        }
        return this.selectedItemData;
      },
      set(value) {
        this.updateSelectedItemData(value);
      },
    },
    editor() {
      return this.$refs.quillEditor;
    },
    instance() {
      return this.selectedItemData.id;
    },
    formClasses() {
      return this.modal ? "survey survey-edit" : "survey student-survey";
    },
  },
  mounted() {
    setup_widgets();
  },
  watch: {
    activeItemData(newValue, previousValue) {
      if(previousValue.description == undefined && newValue.description) {
        console.log("Filling value");
        this.editor?.setHTML(newValue.description);
      }
    }
  }
};
</script>
