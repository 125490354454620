<template>
    <div class="bar-graph-container barGraphContainer" role="presentation" :class="bar_color">
        <div class="loading-container" v-if="loading">
            <div class="loading-indicator"></div>
            <span class="loading-text">Loading...</span>
        </div>
        <template v-else>
            <div class="bar-graph-container barGraphContainer" :class="bar_color" role="presentation">
                <p><i>{{ graph_title }}</i></p>
                <div class="graph">
                    <div class="bar bar-diag bar-outline unread bar-full"></div>
                    <div class="bar bar-solid read" :data-percent="read_percentage" :style="barStyle"></div>
                </div>
                <slot></slot>
            </div>
            <div class="sr-only">
                <p>You've {{ complete_type }} {{ read_percentage }} of available {{ content_label_plural }}.</p>
            </div>
        </template>
    </div>
</template>

<script>
import { loadingMixin } from "../../mixins/loading.js";

export default {
    name: "ActivityBarGraph",
    mixins: [loadingMixin],
    props: {
        graph_title: {
            type: String,
            required: true,
        },
        read_percentage: {
            type: String,
            required: true,
        },
        content_label: {
            type: String,
        },
        complete_type: {
            type: String,
        },
        bar_color: {
            Type: String,
        },
    },
    data() {
        return {};
    },
    computed: {
        content_label_plural() {
            return this.content_label + 's'
        },
        wideWindow() {
            return window.innerWidth > 768;
        },
        baseStyle() {
            return {
                opacity: 1,
                width: this.read_percentage,
            };
        },
        barStyle() {
            let styleObject = this.baseStyle;
            if (this.graphWidth <= 0) {
                styleObject.opacity = 0;
                styleObject.width = '0%';
            } else if(this.graphWidth >= 100){
                styleObject.borderRadius = '10px';
                styleObject.width = '100%';
            }
            return styleObject;
        },
        graphWidth() {
            return parseFloat(this.read_percentage);
        }
    },
};
</script>
