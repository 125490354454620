<template>
  <div class="todo-item" :class="itemCheckedClass()">
    <input class="checkbox-input" :aria-label="`To-Do Item: ${todoitem.title}`" @click="completeItem()" type="checkbox" />
    <div class="item-details">
      <div class="status-label self" v-if="!isStaffCreated">Self-created</div>
      <div class="item-header">
        <div class="item-title">{{ todoitem.title }}</div>
        <div class="item-due date" v-if="todoitem.due_date_short">
          Due: {{ todoitem.due_date_short }}
        </div>
      </div>
      <div
        class="item-description"
        v-if="todoitem.description"
        v-html="todoitem.description"
      ></div>
      <div class="item-footer" v-if="addFooter">
        <div class="todo-tags">
          <div class="tag line-above" v-if="tagCount">
            <span v-for="(tag, index) in todoitem.tags" :key="tag.id">
              {{ tag.label }}<template v-if="index !== tagCount - 1">, </template>
            </span>
          </div>
        </div>
        <div v-if="!isStaffCreated" class="item-self-edit">
          <a v-if="sidebar === 'true'"
            class="btn btn-light-grey"
            type="button"
            @click="openUpdateModal"
            :aria-label="`Edit To-Do Item: ${todoitem.title}`"
          >Edit</a>
          <a v-else
            class="btn btn-light-grey"
            type="button"
            :href="editFormUrl"
            :aria-label="`Edit To-Do Item: ${todoitem.title}`"
          >Edit</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useTodoStore } from '../../stores/todo.js';
import { todoItemUpdateFormUrl } from "../../urls.js";

export default {
  name: "ToDoItem",
  props: {
    "todoitem": {
      required: true
    }, 
    "coachId": {
      required: true
    },
    "sidebar": {
      default: false
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(useTodoStore, [
      'loadTodoListData',
      'completeAccountToDoItem',
      'deleteToDoItem',
      'updateSelectedItemData',
      'updateShowUpdateModal',
    ]),
    completeItem() {
      let completeData = {
        item: this.todoitem.id,
      };
      this.completeAccountToDoItem(completeData);
      this.loadTodoListData();
    },
    itemCheckedClass() {
      if (this.todoitem.account_item[0]) {
        if (this.todoitem.account_item[0].completed) {
          return "checked";
        }
      }
    },
    openUpdateModal() {
      this.updateSelectedItemData(this.todoitem);
      this.updateShowUpdateModal();
    },
  },
  computed: {
    addFooter() {
      return this.tagCount || !this.isStaffCreated;
    },
    tagCount() {
      return this.todoitem.tags.length;
    },
    isStaffCreated() {
      return this.todoitem.staff_created;
    },
    editFormUrl() {
      return todoItemUpdateFormUrl.stringify({coachId: this.coachId, todoItemId: this.todoitem.id});
    },
  },
};
</script>
