import axios from "axios";
import { defineStore } from 'pinia';

import {
    adminCoachBaseUrl,
    bulletinCharURL,
    emailCharURL,
    surveyCharURL,
    playbookCharURL,
    todoCharURL,
    gradebookCharURL,
    courseCharURL,
} from "../urls.js";

const coachInfo = adminCoachBaseUrl.match(location.pathname);
const coachId = coachInfo ? Number(coachInfo.coachId) : null;

const initialState = {
    coachId,
    bulletinCharData: [],
    bulletinCharacteristicURL: coachInfo ? bulletinCharURL.stringify(coachInfo) : null,
    emailCharData: [],
    emailCharacteristicURL: coachInfo ? emailCharURL.stringify(coachInfo) : null,
    surveyCharData: [],
    surveyCharacteristicURL: coachInfo ? surveyCharURL.stringify(coachInfo) : null,
    todoCharData: [],
    todoCharacteristicURL: coachInfo ? todoCharURL.stringify(coachInfo) : null,
    playbookCharData: [],
    playbookCharacteristicURL: coachInfo ? playbookCharURL.stringify(coachInfo) : null,
    gradebookCharData: [],
    gradebookCharacteristicURL: coachInfo ? gradebookCharURL.stringify(coachInfo) : null,
    courseCharData: [],
    courseCharacteristicURL: coachInfo ? courseCharURL.stringify(coachInfo) : null,
};

export const useCharacteristicsStore = defineStore('characteristics', {
    state: () => initialState,
    actions: {
        updateBulletinCharData(payload) {
            this.bulletinCharData = payload;
        },
        loadBulletinCharData() {
            return axios.get(this.bulletinCharacteristicURL).then((response) => {
                this.updateBulletinCharData(response.data);
            });
        },
        updateEmailCharData(payload) {
            this.emailCharData = payload;
        },
        loadEmailCharData() {
            return axios.get(this.emailCharacteristicURL).then((response) => {
                this.updateEmailCharData(response.data);
            });
        },
        updateSurveyCharData(payload) {
            this.surveyCharData = payload;
        },
        loadSurveyCharData() {
            return axios.get(this.surveyCharacteristicURL).then((response) => {
                this.updateSurveyCharData(response.data);
            });
        },
        updateToDoCharData(payload) {
            this.todoCharData = payload;
        },
        loadToDoCharData() {
            return axios.get(this.todoCharacteristicURL).then((response) => {
                this.updateToDoCharData(response.data);
            });
        },
        updatePlaybookCharData(payload) {
            this.playbookCharData = payload;
        },
        loadPlaybookCharData() {
            return axios.get(this.playbookCharacteristicURL).then((response) => {
                this.updatePlaybookCharData(response.data);
            });
        },
        updateGradebookCharData(payload) {
            this.gradebookCharData = payload;
        },
        loadGradebookCharData() {
            return axios.get(this.gradebookCharacteristicURL).then((response) => {
                this.updateGradebookCharData(response.data);
            });
        },
        updateCourseCharData(payload) {
            this.courseCharData = payload;
        },
        loadCourseCharData() {
            return axios.get(this.courseCharacteristicURL).then((response) => {
                this.updateCourseCharData(response.data);
            });
        },
    },
});
