<template>
  <div class="accordion active medium-title button-1">
    <div class="accordion-header">
      <div class="title">Assignments</div>
      <div class="header-container">
        <div class="table-header">MTS Characteristic</div>
        <div class="toggle"></div>
      </div>
    </div>
    <div class="accordion-content">
      <template v-if="showTable">
        <div
            v-for="assignment in gradebook.assignment_characteristics"
            :key="assignment.id"
            class="table-row">
            <div class="row-title">
                {{ assignment.title }}
            </div>
            <div class="info-block">
                <div class="row-item char-wiring overflow-parent">
                    <characteristic-row v-for="char in assignment.characteristics" :key="char" :characteristic="char"></characteristic-row>
                </div>
                <div class="buttons">
                    <a
                        class="edit"
                        :href="`/gradebook/coach/${coachId}/${gradebook.id}/EditAssignment/${assignment.id}/`"
                        :aria-label="`Edit Assignment: ${ assignment.title }`">
                        <span class="tooltip-text">Edit Assignment</span>
                    </a>
                </div>
            </div>
        </div>
      </template>
      <div v-else class="table-row empty">
          No characteristics have been wired for Assignments
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCharacteristicsStore } from "../../stores/characteristics.js";
import CharacteristicRow from "./CharacteristicRow.vue";

export default {
  name: "GradebookAssignmentsTable",
  components: {
    CharacteristicRow,
  },
  data() {
    return {};
  },
  props: {
    gradebook: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useCharacteristicsStore, ["coachId"]),
    showTable(){
      return this.gradebook.assignment_characteristics.length != 0;
    }
  },
};
</script>
