<template>
  <div class="accordion gradebook-chars-accordion active long-title button-1">
    <div class="accordion-header">
      <div class="title">Gradebook MTS Wiring</div>
      <div class="header-container">
        <div class="toggle"></div>
      </div>
    </div>
    <div class="accordion-content">
        <div class="table-row">
            <div class="row-title">
                {{ gradebook.title }}
            </div>
            <div class="info-block">
                <div class="buttons">
                    <a
                        class="edit"
                        :href="`/gradebook/coach/${coachId}/EditGradebook/${gradebook.id}/`"
                        :aria-label="`Edit Gradebook: ${ gradebook.title }`"
                    >
                        <span class="tooltip-text">Edit Gradebook</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="table-row secondary">
            <div class="row-item char-wiring overflow-parent">
                <div class="indent gradebook-chars">
                    <characteristic-row v-for="char in gradebook.characteristics" :key="char" :characteristic="char"></characteristic-row>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCharacteristicsStore } from "../../stores/characteristics.js";
import CharacteristicRow from "./CharacteristicRow.vue";

export default {
  name: "GradebookTable",
  components: {
    CharacteristicRow,
  },
  data() {
    return {};
  },
  props: {
    gradebook: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useCharacteristicsStore, ["coachId"]),
  },
};
</script>
