<template>
    <p class="instructions">
        If an MTS wiring exists, it will show on this page. Settings here may be changed under “advanced setup”.
    </p>
  <div class="dash-section">
    <h2>Communications</h2>
    <loading-indicator v-if="loading" />
    <characteristic-table
      v-else
      title="Bulletin"
      :characteristicData="bulletinCharData"
    ></characteristic-table>
    <loading-indicator v-if="loading" />
    <characteristic-table
      v-else
      title="Email"
      :characteristicData="emailCharData"
    ></characteristic-table>
    <loading-indicator v-if="loading" />
    <characteristic-table
      v-else
      title="Survey"
      :characteristicData="surveyCharData"
    ></characteristic-table>
  </div>
  <div class="dash-section">
    <h2>To-Do Lists</h2>
    <loading-indicator v-if="loading" />
    <characteristic-table
      v-else
      title="To-Do List"
      :characteristicData="todoCharData"
    ></characteristic-table>
  </div>
  <div class="dash-section">
    <h2>Playbooks</h2>
    <loading-indicator v-if="loading" />
    <playbook-table
      v-else
      :characteristicData="playbookCharData"
    ></playbook-table>
  </div>
  <div class="dash-section">
    <h2>Gradebook</h2>
    <template v-for="gradebook in gradebookCharData" :key="gradebook.id">
      <loading-indicator v-if="loading" />
      <gradebook-table v-else :gradebook="gradebook"></gradebook-table>
    </template>
    <template v-for="gradebook in gradebookCharData" :key="gradebook.id">
      <loading-indicator v-if="loading" />
      <gradebook-assignments-table v-else :gradebook="gradebook"></gradebook-assignments-table>
    </template>
  </div>
  <div class="dash-section">
    <h2>Course Tailoring</h2>
    <course-tailoring-table
      :characteristicData="courseCharData"
    ></course-tailoring-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useCharacteristicsStore } from "../../stores/characteristics.js";
import { loadingMixin } from "../../mixins/loading.js"

import LoadingIndicator from '../generic/LoadingIndicator.vue';
import CharacteristicTable from "./CharacteristicTable.vue";
import PlaybookTable from "./PlaybookTable.vue";
import GradebookTable from "./GradebookTable.vue";
import GradebookAssignmentsTable from "./GradebookAssignmentsTable.vue";
import CourseTailoringTable from "./CourseTailoringTable.vue";

export default {
  name: "CharacteristicsOverview",
  mixins: [loadingMixin],
  components: {
    CharacteristicTable,
    PlaybookTable,
    GradebookTable,
    GradebookAssignmentsTable,
    CourseTailoringTable,
    LoadingIndicator,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(useCharacteristicsStore, [
      "loadBulletinCharData",
      "loadEmailCharData",
      "loadSurveyCharData",
      "loadToDoCharData",
      "loadPlaybookCharData",
      "loadGradebookCharData",
      "loadCourseCharData",
    ]),
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.loadBulletinCharData(),
        this.loadEmailCharData(),
        this.loadSurveyCharData(),
        this.loadToDoCharData(),
        this.loadPlaybookCharData(),
        this.loadGradebookCharData(),
        this.loadCourseCharData(),
      ]).then(() => this.stopLoading());
    }
  },
  computed: {
    ...mapState(useCharacteristicsStore, [
      "bulletinCharData",
      "emailCharData",
      "surveyCharData",
      "todoCharData",
      "playbookCharData",
      "gradebookCharData",
      "courseCharData",
    ]),
  },
  created() {
    this.loadInterfaceData();
  },
};
</script>
