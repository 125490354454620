<template>
    <div class="loading-container" v-if="loading">
        <div class="loading-indicator"></div>
        <span class="loading-text">Loading...</span>
    </div>
    <template v-else>
        <div class="bar-graph-container barGraphContainer" :class="bar_color"
            role="presentation">
            <p v-if="show_topics"><i>{{ graph_label }}</i></p>
            <div class="graph">
                <div class="bar bar-diag bar-outline unread" :data-percent="unread_percentage"></div>
                <div class="bar bar-solid read" :data-percent="read_percentage"></div>
            </div>
        </div>
        <div v-if="show_topics" class="sr-only">
            <label>{{ content_label }} topic: {{ graph_label }}</label>
            <p>{{ unread_percentage }} of {{ content_label_plural }} have topic {{ graph_label }}.</p>
            <p>You've {{ complete_type }} {{ read_percentage }} of these {{ content_label_plural }}.</p>
        </div>
        <div v-else class="sr-only">
            <p>You've {{ complete_type }} {{ read_percentage }} of available {{ content_label_plural }}.</p>
        </div>
    </template>
</template>

<script>
import { loadingMixin } from "../../mixins/loading.js";

export default {
  name: "ContentHistoryBarGraph",
  mixins: [loadingMixin],
  props: {
    read_percentage: {
      type: String,
      required: true,
    },
    unread_percentage: {
      type: String,
      required: true,
    },
    graph_text: {
      type: String,
    },
    graph_label: {
      type: String,
    },
    content_label: {
      type: String,
    },
    complete_type: {
      type: String,
    },
    bar_color: {
      Type: String,
    },
    show_topics: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([]).then(() => {
        this.stopLoading();
      });
    },
  },
  created() {
    this.loadInterfaceData();
  },
  computed: {
    content_label_plural() {
      return this.content_label + 's'
    }
  }
};
</script>
