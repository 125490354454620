<template>
  <div class="card-header">
    <h1 v-if="todoItemId">Edit Your To-Do Item</h1>
    <h1 v-else>Add Your Own To-Do Item(s)</h1>
    <button
      class="btn btn-delete"
      type="button"
      @click="deleteItem(instance)"
      :disabled="!instance"
    >
      Delete
    </button>
  </div>
  <to-do-item-form v-if="!loading" :modal="false" :list="list">
    <template v-slot:cancel-button>
      <a
        class="btn btn-cancel"
        type="button"
        :href="redirectUrl"
      >
        Cancel
      </a>
    </template>
  </to-do-item-form>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTodoStore } from "../../stores/todo.js";
import { loadingMixin } from "../../mixins/loading.js";

import ToDoItemForm from './ToDoItemForm.vue';

export default {
  name: "ToDoStandalone",
  data() {
    return {
      formData: {},
      formErrors: {},
    };
  },
  components: {
    ToDoItemForm,
  },
  mixins: [loadingMixin],
  methods: {
    ...mapActions(useTodoStore, [
      "loadTodoListData",
      "todoItemId",
      "deleteToDoItem",
      "updateSelectedItemData",
      "loadTodoItemTagsData",
      "loadTodoItemData",
    ]),
    deleteItem(itemId) {
      this.deleteToDoItem(itemId).then((response) => {
        this.loadTodoListData();
        window.location.href = this.redirectUrl;
      });
      this.formErrors = {};
    },
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.loadTodoListData,
        (this.todoItemId ? this.loadTodoItemData() : null),
        this.loadTodoItemTagsData(),
      ]).then(() => {
        this.stopLoading();
        console.log(this.todoListData);
      });
    }
  },
  computed: {
    ...mapState(useTodoStore, [
      "todoItemId",
      "selectedItemData",
      "redirectUrl",
      "todoListData"
    ]),
    instance() {
      return this.selectedItemData.id;
    },
    list() {
      return new URLSearchParams(window.location.search).get('list');
    },
  },
  created() {
    this.loadInterfaceData();
  }
};
</script>
