import UrlPattern from 'url-pattern';

// Wildcard Match URLs //
export const coachBaseUrl = new UrlPattern('*/coach/:coachId/*');
export const institutionBaseUrl = new UrlPattern ('*/Homepage/:institutionId/*');
export const adminInsitutionBaseURL = new UrlPattern('/Dashboard/Institution/:institutionId/*');
export const adminCoachBaseUrl = new UrlPattern('/Dashboard/Coach/:coachId/*');
export const courseCodeBaseUrl = new UrlPattern('/gradebook/:courseCode/*');
export const careerBaseUrl = new UrlPattern('*/Advising/:careerId/*');
export const studentAdvisorBaseUrl = new UrlPattern('*/Student/:studentId');
export const todoItemBaseUrl = new UrlPattern('*/ChecklistItem/:todoItemId/*');

export const gradeDataUrl = new UrlPattern('/gradebook/coach/:coachId/GradeLeftData/');
export const gradebookDataUrl = new UrlPattern('/gradebook/coach/:coachId/GradeCalculatorData/');
export const gradebookDataCourseCodeUrl = new UrlPattern('/gradebook/:courseCode/GradeCalculatorData/');
export const recordEventUrl = new UrlPattern('/Dashboard/RecordEvent');

export const coachHomeUrl = new UrlPattern('/coach/:coachId/home/');

export const coachFaqListURL = new UrlPattern('/Dashboard/coach/:coachId/FAQList/');
export const coachSendEmailURL = new UrlPattern('/Dashboard/coach/:coachId/FAQSupportEmail/');

export const institutionFaqListUrl = new UrlPattern('/Dashboard/Institution/:institutionId/FAQList/');
export const institutionSendEmailUrl = new UrlPattern('/Dashboard/Institution/:institutionId/FAQSupportEmail/');

export const generalFaqListUrl = new UrlPattern('/AccountProfile/GeneralFAQList/');
export const generalFaqEmailUrl = new UrlPattern('/AccountProfile/GeneralFAQEmail/');

export const institutionImageListData = new UrlPattern('/Dashboard/Institution/:institutionId/ImageList/');
export const institutionImageSelectionUrl = new UrlPattern('/Dashboard/Institution/:institutionId/ImageSelect/');
export const institutionImageUpdateSelectionUrl = new UrlPattern('/Dashboard/Institution/:institutionId/ImageSelect/:objectId');

export const coachImageListData = new UrlPattern('/Dashboard/coach/:coachId/ImageList/');
export const coachImageSelectionUrl = new UrlPattern('/Dashboard/coach/:coachId/ImageSelect/');
export const coachImageUpdateSelectionUrl = new UrlPattern('/Dashboard/coach/:coachId/ImageSelect/:objectId');

export const messageWildcardUrl = new UrlPattern('/Dashboard/*/EditBulletin/:objectId/');
export const messageCenterUrl = new UrlPattern('*/MessageCenter/:coachId/*');
export const institutionMessageCenterUrl = new UrlPattern('*/InstitutionMessageCenterView/:institutionId/*');

export const coachSurveyUrl = new UrlPattern('*/ECoachSurvey/:coachId/*');
export const institutionSurveyUrl = new UrlPattern('*/ECoachInstitutionSurvey/:institutionId/*');

export const coachHistoryUrl = new UrlPattern('*/CoachHistory/:coachId/*');
export const institutionHistoryUrl = new UrlPattern('*/InstitutionHistory/:institutionId/*');

export const coachBulletinBoardUrl = new UrlPattern('*/MessageCenter/:coachId*');
export const institutionBulletinBoardUrl = new UrlPattern('*/InstitutionMessageCenterView/:institutionId*');

export const coachBulletinListUrl = new UrlPattern('/api/v1/coach/:coachId/bulletin-list');
export const institutionBulletinListUrl = new UrlPattern('/api/v1/institution/:institutionId/bulletin-list');

export const coachStarBulletinUrl = new UrlPattern('/api/v1/coach/star-message/:coachId/:bulletinId/');
export const institutionStarBulletinUrl = new UrlPattern('/api/v1/institution/star-message/:institutionId/:bulletinId/');

export const coachBulletinPageUrl = new UrlPattern('/MessageCenter/:coachId/:bulletinId/');
export const coachInteractiveBulletinPageUrl = new UrlPattern('/InteractiveMessage/:coachId/:bulletinId/:pageId/');
export const institutionBulletinPageUrl = new UrlPattern('/InstitutionMessageCenterView/:institutionId/:bulletinId/');
export const institutionInteractiveBulletinPageUrl = new UrlPattern('/InstitutionInteractiveMessage/:institutionId/:bulletinId/:pageId/');

export const todoListOverviewUrl = new UrlPattern('/coach/:coachId/ToDoList/Overview/');
export const createTodoItemUrl = new UrlPattern('*/api/v1/coach/:coachId/to-do-item');
export const updateTodoItemUrl = new UrlPattern('/api/v1/coach/:coachId/to-do-item/:todoItemId');
export const todoItemUpdateFormUrl = new UrlPattern('/coach/:coachId/ToDoList/ChecklistItem/:todoItemId/');
export const todoItemCreateFormUrl = new UrlPattern('/coach/:coachId/ToDoList/ChecklistItem?list=:listId');

export const todoListDataUrl = new UrlPattern('*/api/v1/coach/:coachId/to-do-list');
export const createAccountTodoItemUrl = new UrlPattern('*/api/v1/coach/:coachId/account-to-do-item');
export const todoItemTagsUrl = new UrlPattern('*/api/v1/coach/:coachId/todo-item-tags');

export const tipsDocumentUrl = new UrlPattern('/api/v1/coach/:coachId/tips');

export const coachListURL = new UrlPattern('/api/v1/institution/:institutionId/student-coachlist');
export const activityListUrl = new UrlPattern('/api/v1/coach/:coachId/activityhistory-credits');
export const activityListNoCreditUrl = new UrlPattern('/api/v1/coach/:coachId/activityhistory');
export const activityMessageAnalyticsUrl = new UrlPattern('/api/v1/coach/:coachId/activityhistory-bulletin-analytics');
export const activitySurveyAnalyticsUrl = new UrlPattern('/api/v1/coach/:coachId/activityhistory-survey-analytics');
export const activityToDoAnalyticsUrl = new UrlPattern('/api/v1/coach/:coachId/activityhistory-todo-analytics');
export const activityExamPlaybookAnalyticsUrl = new UrlPattern('/api/v1/coach/:coachId/activityhistory-examplaybook-analytics');
export const activityCoachUserAnalyticsUrl = new UrlPattern('/api/v1/coach/:coachId/coachuser-analytics');

export const bulletinCharURL = new UrlPattern('/api/v1/coach/:coachId/characteristics/bulletins');
export const emailCharURL = new UrlPattern('/api/v1/coach/:coachId/characteristics/emails');
export const surveyCharURL = new UrlPattern('/api/v1/coach/:coachId/characteristics/surveys');
export const todoCharURL = new UrlPattern('/api/v1/coach/:coachId/characteristics/todolists');
export const playbookCharURL = new UrlPattern('/api/v1/coach/:coachId/characteristics/playbooks');
export const gradebookCharURL = new UrlPattern('/api/v1/coach/:coachId/characteristics/gradebooks');
export const courseCharURL = new UrlPattern('/api/v1/coach/:coachId/characteristics/course');
