<template>
  <div class="accordion active playbook-char-accordion short-title button-1">
    <div class="accordion-header">
      <div class="title">Playbooks</div>
      <div class="header-container">
        <div class="table-header">MTS Characteristic</div>
        <div class="table-header status-column">Status</div>
        <div class="toggle"></div>
      </div>
    </div>
    <div class="accordion-content">
      <span v-if="showTable">
        <template v-for="playbook in characteristicData" :key="playbook.id">
            <div class="table-row">
                <div class="row-title">
                    {{ playbook.title }}
                </div>
                <div class="info-block">
                    <div class="buttons">
                        <a
                            class="edit"
                            :href="`/Dashboard/coach/${coachId}/PlaybookOverview/EditPlaybook/${playbook.id}/`"
                            :aria-label="`Edit Playbook: ${ playbook.title }`"
                        >
                            <span class="tooltip-text">Edit Playbook</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="table-row secondary">
                <div class="row-title">
                    <div class="indent">Planning</div>
                </div>
                <div class="info-block">
                    <div class="row-item char-wiring planning-chars overflow-parent">
                        <div class="char-block general-block">
                            <characteristic-row
                                v-for="char in playbook.planning_characteristics"
                                :key="char"
                                :characteristic="char"
                            ></characteristic-row>
                        </div>
                        <div class="char-block emotions-block">
                            <characteristic-row
                                v-for="char in playbook.planning_emotions_characteristics"
                                :key="char"
                                :characteristic="char"
                            ></characteristic-row>
                        </div>
                    </div>
                    <div class="row-item status-column">
                        {{ playbook.planning_status }}
                    </div>
                    <div class="buttons"></div>
                </div>
            </div>
            <div class="table-row secondary">
                <div class="row-title">
                    <div class="indent">Reflection</div>
                </div>
                <div class="info-block">
                    <div class="row-item char-wiring overflow-parent">
                        <characteristic-row
                            v-for="char in playbook.reflection_characteristics"
                            :key="char"
                            :characteristic="char"
                        ></characteristic-row>
                    </div>
                    <div class="row-item status-column">
                        {{ playbook.planning_status }}
                    </div>
                    <div class="buttons"></div>
                </div>
            </div>
        </template>
        </span>
        <span v-else class="table-row empty">
          No characteristics have been wired for Playbooks
        </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCharacteristicsStore } from "../../stores/characteristics.js";

import CharacteristicRow from "./CharacteristicRow.vue";

export default {
  name: "PlaybookTable",
  components: {
    CharacteristicRow,
  },
  data() {
    return {};
  },
  props: {
    characteristicData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(useCharacteristicsStore, ["coachId"]),
    showTable() {
      return this.characteristicData.length != 0;
    },
  },
};
</script>
