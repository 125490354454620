<template>
    <div class="loading-container" v-if="loading">
        <div class="loading-indicator"></div>
        <span class="loading-text">Loading...</span>
    </div>
    <div class="points-left" v-else>
        <div v-if="gradeEarned == 0" class="card card-empty">
            <div class="icon-container">
                <svg class="empty-icon" role="presentation">
                    <use xlink:href="/static/img/IconSprite.svg#percent"></use>
                </svg>
            </div>
            <p>
                <i>
                    You haven’t earned any points yet in {{ coachname }}.
                    <template v-if="calculatorActive">
                        Play around with the <strong>Grade Calculator</strong> to see your potential grade.
                    </template>
                </i>
            </p>
        </div>
        <div v-else class="card card-points">
            <div class="graph-block">
                <p v-if="gradeRemaining == 0">
                    There are no points left to earn for this term.
                </p>
                <p v-else>See what percent of points you earned, you missed, or are still available.</p>
                <div class="bar-graph-container graph-salmon">
                    <div class="graph">
                        <div class="remaining-bar bar bar-diag bar-full"
                            v-if="gradeRemaining > 0">
                            <label class="label remain-label">
                                <span class="large">
                                    {{ gradeRemaining }}%
                                </span>
                                <span>are still<br> available</span>
                            </label>
                        </div>
                        <div class="missed-bar bar"
                            role="presentation"
                            :style="missedBarStyle"
                            v-if="gradeLost > 0 && gradeAllocated !== 100">
                        </div>
                        <label class="label missed-label"
                            :style="missedLabelStyle"
                            v-if="gradeLost > 0">
                            <span class="sr-only">{{ missedAmount }}</span>
                            missed
                        </label>
                        <div class="earned-bar bar bar-solid"
                            :class="{ 'bar-full': gradeEarned == 100 }"
                            :style="earnedBarStyle"
                            v-if="gradeEarned > 0">
                            <label class="label earned-label">
                                <span class="sr-only">{{ earnedAmount }}</span>
                                earned
                            </label>
                        </div>
                        <div class="current-label"
                            role="presentation"
                            v-if="gradeRemaining > 0"
                            :style="currentLabelStyle">
                            You are
                            <br>
                            here
                        </div>
                    </div>
                </div>
            </div>
            <div class="graph-key">
                <div class="key-item key-salmon key-solid key-earned">
                    <div class="key-color"></div>
                    <div class="key-label">
                        <b>{{ earnedAmount }}</b><br>
                        earned
                    </div>
                </div>
                <div class="key-item key-salmon key-missed">
                    <div class="key-color"></div>
                    <div class="key-label">
                        <b>{{ missedAmount }}</b><br>
                        missed
                    </div>
                </div>
                <div class="key-item key-salmon key-diag key-remaining">
                    <div class="key-color"></div>
                    <div class="key-label">
                        <b>{{ gradeRemaining }}%</b><br>
                        available
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useGradeVizStore } from '../../stores/grade-viz.js';

export default({
  name: 'GradeLeftVisualization',
  props: {
    coachname: {
      type: String,
      required: true,
    },
  },
  data () {
      return {};
  },
  methods: {
    ...mapActions(useGradeVizStore, [
      'loadGradeData',
    ]),
  },
  computed: {
    ...mapState(useGradeVizStore, [
      'gradeData',
      'currentScore',
      'gradeEarned',
      'gradeLost',
      'calculatorActive',
    ]),
    loading: function () {
      return Object.keys(this.gradeData).length === 0 && this.gradeData.constructor === Object;
    },
    gradeAllocated: function () {
      return this.gradeEarned + this.gradeLost;
    },
    gradeRemaining: function () {
      return Math.round(this.gradeData.grade_remaining);
    },
    missedLabelStyle: function () {
      return {left: this.gradeAllocated - (this.gradeLost / 2) + "%"};
    },
    currentLabelStyle: function () {
      return {left: this.gradeAllocated + "%"};
    },
    earnedBarStyle: function () {
      return {width: this.gradeEarned + "%"};
    },
    missedBarStyle: function () {
      return {width: this.gradeAllocated + "%"};
    },
    earnedAmount: function () {
      return this.gradeEarned + "%";
    },
    missedAmount: function () {
      let missedPercent = this.gradeAllocated - this.gradeEarned;

      return (Math.round((missedPercent + Number.EPSILON) * 100) / 100) + "%";
    }
  },
  created: function () {
    this.loadGradeData();
  }
});
</script>
