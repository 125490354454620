<template>
  <div class="card-header">
    <h2>Bulletins</h2>
  </div>
  <div v-if="showMessageAnalytics"
    class="card card-has-divider card-bulletin-activity activityBreakdown">
    <div class="divided-block small-divided-block has-right-border">
      <content-count-display
        content_type="bulletin"
        complete_type="read"
        content_tag="bulletin"
        bar_color="graph-yellow"
        :complete_label="complete_label"
        :incomplete_label="incomplete_label"
        :show_progress_bar="showProgressBar"
        :completed="bulletinActivityHistory.read_messages_count"
        :completed_percentage="bulletinActivityHistory.read_messages_percentage"
        :incomplete="bulletinActivityHistory.unread_messages_count"
        :total="bulletinActivityHistory.total_messages_count"
      />
    </div>
    <div class="divided-block graph-block">
      <div class="nested-card-header header-plus-key has-mobile-break">
        <h3 v-if="showCategoryBreakdown">Category Breakdown</h3>
        <h3 v-else>Bulletin Activity</h3>
        <div class="graph-key horizontal">
          <div class="key-item key-yellow key-solid key-read">
            <div class="key-color"></div>
            <div class="key-label">Read</div>
          </div>
          <div class="key-item key-yellow key-diag key-unread">
            <div class="key-color"></div>
            <div class="key-label">Unread</div>
          </div>
          <div v-if="showCategoryBreakdown" class="key-item key-total">
            <div class="key-color"></div>
            <div class="key-label">Total</div>
          </div>
        </div>
      </div>
      <div class="graph-section horizontal">
        <span v-if="hasOneorMoreMessages">
          <template v-if="showTopicGraph">
            <template
              v-for="topic in bulletinActivityHistory.message_topics"
              :key="topic.topic_name"
              :topic="topic">
              <content-history-bar-graph
                v-if="topic.num_messages"
                :show_topics="true"
                complete_type="read"
                bar_color="graph-yellow"
                :content_label="bulletinActivityHistory.content_label"
                :read_percentage="topic.read_percentage"
                :unread_percentage="topic.unread_percentage"
                :graph_label="topic.topic_name"
                :graph_text="topic.topic_name"
              />
            </template>
          </template>
          <template v-if="bulletinActivityHistory.message_without_topic">
            <content-history-bar-graph
              complete_type="read"
              bar_color="graph-yellow"
              :content_label="bulletinActivityHistory.content_label"
              :read_percentage="bulletinActivityHistory.messages_without_topic_read_percentage"
              :unread_percentage="bulletinActivityHistory.messages_without_topic_unread_percentage"
              graph_label="General Bulletins"
              graph_text="General"
              :show_topics="showTopicGraph"
            />
          </template>
        </span>
        <span v-else>
          <div
            class="bar-graph-container graph-yellow barGraphContainer"
            role="presentation"
          >
            <div v-if="hasOneorMoreMessages" class="category-section">
              <p>
                <i>
                  <b>Category: </b>
                  <template
                    v-for="(topic, index) in bulletinActivityHistory.message_topics"
                    :key="topic.topic_name"
                  >
                    {{ topic.topic_name}}
                    <template
                      v-if="index !== Object.keys(bulletinActivityHistory.message_topics).length - 1"
                    >,
                    </template>
                  </template>
                </i>
              </p>
            </div>
            <div class="graph">
              <div class="bar bar-diag bar-outline unread" data-percent="{{topic.unread_percentage}}%"></div>
              <div class="bar bar-solid read" data-percent="{{topic.read_percentage}}%"></div>
            </div>
          </div>
          <div class="sr-only">
            <label>Bulletins</label>
            <p>You’ve read {{ topic.read_percentage }}% of bulletins.</p>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div v-else class="card card-empty">
    <div class="icon-container">
      <svg class="empty-icon" role="presentation">
        <use href="/static/img/IconSprite.svg#bulletin"></use>
      </svg>
    </div>
    <p v-if="coach_name">There are currently no Bulletins available in {{ coach_name }}.</p>
    <p v-else>There are currently no Bulletins available.</p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useStudentActivityStore } from '../../stores/activity-history.js';

import { loadingMixin } from "../../mixins/loading.js";

import ContentCountDisplay from "./ContentCountDisplay.vue";
import ContentHistoryBarGraph from "./ContentHistoryBarGraph.vue";

const COMPLETE_LABEL = "Read";
const INCOMPLETE_LABEL = "Unread";

export default {
  name: "BulletinActivityHistory",
  mixins: [loadingMixin],
  components: {
    ContentCountDisplay,
    ContentHistoryBarGraph,
  },
  data() {
    return {
      complete_label: COMPLETE_LABEL,
      incomplete_label: INCOMPLETE_LABEL,
      coach_name: "",
    };
  },
  methods: {
    ...mapActions(useStudentActivityStore, ['getActivityMessageAnalytics']),
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([this.getActivityMessageAnalytics()]).then(() => {
        setBarStyle();
        this.stopLoading();
      });
    },
  },
  computed: {
    ...mapState(useStudentActivityStore, ['bulletinActivityHistory']),
    hasMultipleTopics() {
      return this.bulletinActivityHistory.total_message_topics_count > 1;
    },
    hasMultipleMessages() {
      return this.bulletinActivityHistory.total_messages_count > 1;
    },
    hasOneorMoreMessages() {
      return this.bulletinActivityHistory.total_messages_count >= 1;
    },
    showMessageAnalytics() {
      return this.bulletinActivityHistory.total_messages_count > 0;
    },
    showProgressBar() {
      return this.hasMultipleTopics && this.hasMultipleMessages;
    },
    showCategoryBreakdown() {
      return this.hasMultipleTopics && this.hasMultipleMessages;
    },
    showTopicGraph() {
      return this.bulletinActivityHistory.total_message_topics_count >= 1;
    },
  },
  created() {
    this.loadInterfaceData();
  },
  mounted() {
    this.coach_name = document.getElementById("BulletinActivityHistory").getAttribute("coach_name");
  }
};
</script>
