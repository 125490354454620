<template>
    <div class="card-header">
        <h2>To-Dos</h2>
    </div>
    <div v-if="showToDoAnalytics"
        class="card card-has-divider card-todo-activity activityBreakdown">
        <div class="divided-block small-divided-block has-right-border">
            <content-count-display
                content_type="To-Do Item"
                complete_type="completed"
                content_tag="toDo"
                bar_color="graph-bright-blue"
                :complete_label="complete_label"
                :incomplete_label="incomplete_label"
                :show_progress_bar="showProgressBar"
                :completed="toDoActivityHistory.completed_todo_item_count"
                :completed_percentage="toDoActivityHistory.completed_todo_item_percentage"
                :incomplete="toDoActivityHistory.incomplete_todo_item_count"
                :total="toDoActivityHistory.total_todo_item_count"
            />
        </div>
        <div class="divided-block graph-block">
            <div class="nested-card-header header-plus-key has-mobile-break">
                <h3 v-if="showCategoryBreakdown">Category Breakdown</h3>
                <div v-else class="empty"></div>
                <div class="graph-key horizontal">
                    <div class="key-item key-bright-blue key-solid key-read">
                        <div class="key-color"></div>
                        <div class="key-label">Complete</div>
                    </div>
                    <div class="key-item key-bright-blue key-diag key-unread">
                        <div class="key-color"></div>
                        <div class="key-label">Incomplete</div>
                    </div>
                    <div class="key-item key-total">
                        <div class="key-color"></div>
                        <div class="key-label">Total</div>
                    </div>
                </div>
            </div>
            <div class="graph-section horizontal">
                <template v-if="hasMultipleCategories">
                    <template v-for="tag in toDoActivityHistory.todoitem_tags"
                        :key="tag.id"
                        :tag="tag.label">
                        <content-history-bar-graph
                            complete_type="completed"
                            bar_color="graph-bright-blue"
                            :content_label="toDoActivityHistory.content_label"
                            :read_percentage="tag.completed_percentage"
                            :unread_percentage="tag.incomplete_percentage"
                            :graph_label="tag.label"
                            :graph_text="tag.label"
                            :show_topics="true"
                        />
                    </template>
                    <template v-if="hasUntaggedToDoItems">
                        <content-history-bar-graph
                            complete_type="completed"
                            bar_color="graph-bright-blue"
                            :content_label="toDoActivityHistory.content_label"
                            :read_percentage="toDoActivityHistory.todoitems_without_tags_completed_percentage"
                            :unread_percentage="toDoActivityHistory.todoitems_without_tags_incomplete_percentage"
                            graph_label="General"
                            graph_text="General"
                            show_topics="hasMultipleCategories"
                        />
                    </template>
                </template>
                <template v-else>
                    <div class="bar-graph-container graph-bright-blue barGraphContainer"
                        role="presentation">
                        <div v-if="hasAtLeastOneTag"
                            class="category-section">
                            <p>
                                <i>
                                    <b>Category: </b>
                                    <template v-for="(tag, index) in toDoActivityHistory.todoitem_tags"
                                        :key="tag.id"
                                        :tag="tag">
                                        {{ tag.label }}<template v-if="index !== Object.keys(toDoActivityHistory.todoitem_tags).length - 1">, </template>
                                    </template>
                                </i>
                            </p>
                        </div>
                        <div class="graph">
                            <div class="bar bar-diag bar-outline unread"
                                data-percent="100.0%">
                            </div>
                            <div class="bar bar-solid read"
                                :data-percent="toDoActivityHistory.completed_todo_item_percentage">
                            </div>
                        </div>
                    </div>
                    <div class="sr-only">
                        <label>To-Do list items</label>
                        <p>
                            You've completed {{ toDoActivityHistory.completed_todo_item_percentage }} of To-Do items.
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div v-else class="card card-empty">
        <div class="icon-container">
            <svg class="empty-icon" role="presentation">
                <use href="/static/img/IconSprite.svg#toDo"></use>
            </svg>
        </div>
        <p v-if="coach_name">There are currently no To-Do items available in {{ coach_name }}.</p>
        <p v-else>There are currently no To-Do items available.</p>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useStudentActivityStore } from '../../stores/activity-history.js';

import { loadingMixin } from "../../mixins/loading.js";
import ContentCountDisplay from "./ContentCountDisplay.vue";
import ContentHistoryBarGraph from "./ContentHistoryBarGraph.vue";

const GRAPH_ORIENTATION = "horizontal";
const COMPLETE_LABEL = "Complete";
const INCOMPLETE_LABEL = "Incomplete";

export default {
  name: "ToDoActivityHistory",
  mixins: [loadingMixin],
  components: {
    ContentCountDisplay,
    ContentHistoryBarGraph,
  },
  data() {
    return {
      complete_label: COMPLETE_LABEL,
      incomplete_label: INCOMPLETE_LABEL,
      coach_name: "",
    };
  },
  methods: {
    ...mapActions(useStudentActivityStore, ['getActivityToDoAnalytics']),
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([this.getActivityToDoAnalytics()]).then(() => {
        setBarStyle();
        this.stopLoading();
      });
    },
  },
  computed: {
    ...mapState(useStudentActivityStore, ['toDoActivityHistory']),
    hasAtLeastOneToDoItem() {
      return this.toDoActivityHistory.total_todo_item_count;
    },
    showToDoAnalytics() {
      return this.hasAtLeastOneToDoItem;
    },
    hasAtLeastOneTag() {
      return this.toDoActivityHistory.tag_todoitem_count;
    },
    hasMultipleTags() {
      return this.toDoActivityHistory.tag_todoitem_count > 1;
    },
    hasUntaggedToDoItems() {
      return this.toDoActivityHistory.todoitems_without_tags;
    },
    hasMultipleCategories() {
      return this.hasMultipleTags || (this.hasAtLeastOneTag && this.hasUntaggedToDoItems);
    },
    hasMultipleToDoItems() {
      return this.toDoActivityHistory.total_todo_item_count > 1;
    },
    showProgressBar() {
      return this.hasMultipleToDoItems && this.hasMultipleCategories;
    },
    showCategoryBreakdown() {
      return this.hasMultipleToDoItems && this.hasMultipleCategories;
    }
  },
  created() {
    this.loadInterfaceData();
  },
  mounted() {
    this.coach_name = document.getElementById("ToDoActivityHistory").getAttribute("coach_name");
  }
};
</script>
