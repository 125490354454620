<template>
  <div>
    <modal
      :modalClasses="modalClasses"
      @modal-cancel="updateShowModal"
    >
      <focus-trap>
        <div>
          <div class="modal-header">
            <h2 v-if="instance">Edit Your To-Do Item</h2>
            <h2 v-else>Add Your Own To-Do Item</h2>
            <button
              ref="firstFocusableElement"
              class="btn modal-close"
              type="button"
              aria-label="Close modal"
              @click="updateShowModal"
            >
              <svg class="modal-icon icon-navy">
                <use :xlink:href="exitIcon"></use>
              </svg>
            </button>
          </div>
          <div :style="{'text-align': 'right'}">
            <button v-if="selectedItemData.account_item"
              class="btn btn-delete"
              type="button"
              @click="deleteItem(instance)"
            >
              Delete
            </button>
          </div>
          <to-do-item-form v-if="!loading" :modal="true">
            <template v-slot:cancel-button>
              <button
                class="btn btn-cancel"
                type="button"
                @click="updateToDoShowUpdateModal()"
              >
                Cancel
              </button>
            </template>
          </to-do-item-form>
        </div>
      </focus-trap>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTodoStore } from "../../stores/todo.js";
import { loadingMixin } from "../../mixins/loading.js";

import { FocusTrap } from "focus-trap-vue";
import { exitIcon } from "../../images.js";
import { QuillEditor } from '@vueup/vue-quill'

import Modal from "../generic/Modal.vue";
import ToDoItemForm from './ToDoItemForm.vue';

import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "ToDoModal",
  data() {
    return {
      description: '',
      exitIcon: exitIcon,
      formErrors: {},
      index: 0,
    };
  },
  mixins: [loadingMixin],
  components: {
    Modal,
    ToDoItemForm,
    FocusTrap,
    QuillEditor
  },
  methods: {
    ...mapActions(useTodoStore, [
      "loadTodoListData",
      "createToDoItem",
      "updateToDoItem",
      "deleteToDoItem",
      "updateSelectedItemData",
      "updateShowCreateModal",
      "updateShowUpdateModal",
    ]),
    cleanData(data) {
      data.due_date = this.cleanDate(data.due_date);
      data.description = this.getDescription();
      console.log(data);
      return data;
    },
    cleanDate(dateString) {
      if (dateString != $(".datepicker").val()) {
        dateString = $(".datepicker").val();
      }
      let formattedDate = this.$luxonDateTime.fromFormat(
        dateString,
        "MM/dd/yyyy"
      );
      formattedDate = formattedDate.isValid
        ? formattedDate.toISODate()
        : dateString;
      return formattedDate ? formattedDate : null;
    },
    deleteItem(itemId) {
      this.deleteToDoItem(itemId).then((response) => {
        this.loadTodoListData();
        this.updateShowUpdateModal();
        this.updateSelectedItemData({});
      });
      this.formErrors = {};
    },
    editItem(data) {
      this.updateToDoItem(data).then((response) => {
        this.handleUpdate(response);
      });
    },
    formatDate(date) {
      const formattedDate = this.$luxonDateTime.fromFormat(date, "yyyy-MM-dd");
      return formattedDate.isValid
        ? formattedDate.toFormat("MM/dd/yyyy")
        : date;
    },
    handleUpdate(response) {
      if (response.errors) {
        this.formErrors = response.errors;
      } else {
        this.loadTodoListData();
        this.formErrors = {};
      }
    },
    focusWithinModal() {
      this.$refs.firstFocusableElement.focus();
    },
    saveItem(data) {
      this.createToDoItem(data).then((response) => {
        this.handleUpdate(response);
      });
    },
    submitTodoForm(){
      let data = this.cleanData(this.selectedItemData);
      this.showCreateModal ? this.saveItem(data) : this.editItem(data);
    },
    updateShowModal() {
      this.showCreateModal ? this.updateToDoShowCreateModal() : this.updateToDoShowUpdateModal();
      this.updateSelectedItemData({});
    },
    updateToDoShowCreateModal() {
      this.updateShowCreateModal();
      this.formErrors = {};
    },
    updateToDoShowUpdateModal() {
      this.updateShowUpdateModal();
      this.formErrors = {};
    },
  },
  computed: {
    ...mapState(useTodoStore, [
      "coachToDoItemTags",
      "newItemData",
      "selectedItemData",
      "showCreateModal",
      "showUpdateModal",
      "todoListData",
    ]),
    instance() {
      return this.selectedItemData.id;
    },
    modalClasses() {
      let baseClasses = 'todo-modal active';
      if(this.instance) {
        return baseClasses + ' todo-edit';
      }
      return baseClasses;
    }
  },
  mounted() {
    this.$nextTick(() => {  
      this.focusWithinModal();
    });
  }
};
</script>
